import styles from "./styles/Persona.module.css";
import { PhoneIcon } from "svg/CustomSVGIcon";

const SelectedByerPersona = ({
  image,
  name,
  designation,
  quirk,
  industry,
  description,
  number,
}) => {
  return (
    <div className={styles.buyer_persona_info}>
      <h1>Selected Buyer Persona</h1>
      <img src={image} alt={name} />
      <h2>{name}</h2>
      <p>{designation}</p>
      <div className={styles.quirk}>{quirk}</div>
      <div className={styles.company}>
        <h3>{industry}</h3>
        <p>{description}</p>
      </div>
      <button className={styles.phone}>
        <PhoneIcon fill={"#fff"} />
        <span>Start Call</span>
      </button>
    </div>
  );
};

const SelectedPersona = () => {
  return (
    <div>
      <SelectedByerPersona
        image="https://cdn1.edgedatg.com/aws/v2/abc/SharkTank/person/942357/658ffd051a71b2cb8bcae612645a957d/579x579-Q90_658ffd051a71b2cb8bcae612645a957d.jpg"
        name="Mark Cuban"
        designation="Senior Director of Business Operations, Acme Inc."
        quirk="Cordial"
        industry="AI Roleplay Instruction"
        description={
          "Decision maker at Acme Inc. with a background in internet radio and sports."
        }
      />
    </div>
  );
};

export default SelectedPersona;
