import { BuyerPersonasIcon, LeaderboardIcon } from "svg/CustomSVGIcon";
import styles from "./styles/ConversationalAI.module.css";
import { MainContent } from "components/sidebar";
import organizationAPI from "api/api";
import LoadingOverlay from "components/LoadingOverlay";
import ErrorOverlay from "components/ErrorOverlay";
import React, { useState, useEffect } from "react";
import Persona from "./Persona";
import SelectedPersona from "./SelectedPersona";
import Leaderboard from "./Leaderboard";

const BuyerPersona = ({ active = false, title, setNav }) => {
  return (
    <a
      className={styles.navbar_title}
      onClick={() =>
        setNav({
          persona: true,
          leaderboard: false,
        })
      }
    >
      <div className={styles.left_navigation}>
        <BuyerPersonasIcon fill={active ? "#E20074" : "#8CA19F"} />
        <h4>{title}</h4>
      </div>
      {active && <div className={styles.navigation_border} />}
    </a>
  );
};

const Leaderboards = ({ active = false, title, setNav }) => {
  return (
    <a
      className={styles.navbar_title}
      onClick={() =>
        setNav({
          persona: false,
          leaderboard: true,
        })
      }
    >
      <div className={styles.left_navigation}>
        <LeaderboardIcon fill={active ? "#E20074" : "#8CA19F"} />
        <h4 className={active ? styles.h4_active : styles.h4_inactive}>
          {title}
        </h4>
      </div>
      {active && <div className={styles.navigation_border} />}
    </a>
  );
};

const ConversationalAI = () => {
  const [loading, setLoading] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [user, setUser] = useState({});
  const [nav, setNav] = useState({
    persona: true,
    leaderboard: false,
  });

  const handleCloseError = () => {
    setErrorVisible(false);
  };

  const getMe = async () => {
    setLoading(true);
    try {
      const response = await organizationAPI.getMe();
      setUser(response.data.user);
    } catch (error) {
      console.log(error);
      setErrorVisible(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMe();
  }, []);

  

  return (
    <MainContent active={"query"} user={user}>
      {loading ? <LoadingOverlay></LoadingOverlay> : null}
      <ErrorOverlay showOverlay={errorVisible} onClose={handleCloseError} />
      <div className={styles.content}>
        <h1>T-Mobile Sales: Conversational AI </h1>
        <div className={styles.border}></div>
        <div className={styles.container}>
          <div className={styles.left_container}>
            <div className={styles.navbar}>
              <BuyerPersona
                active={nav.persona}
                title={"Buyer Personas"}
                setNav={setNav}
              />
              <Leaderboards
                active={nav.leaderboard}
                title={"Leaderboard"}
                setNav={setNav}
              />
            </div>
            {nav.persona && !nav.leaderboard ? <Persona /> : <Leaderboard />}
          </div>

          <div className={styles.right_container}>
            <SelectedPersona />
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default ConversationalAI;
