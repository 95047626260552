import React, { useEffect, useState } from "react";
import styles from "./styles/Query.module.css";
import { useNavigate } from "react-router-dom";
import { MainContent, Sidebar } from "components/sidebar";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import UndoOutlinedIcon from "@mui/icons-material/UndoOutlined";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FormControl from "@mui/material/FormControl";
import { useLocation } from "react-router-dom";
import moment from "moment";
import LoadingOverlay from "components/LoadingOverlay";
import organizationAPI from "api/api";
import { CustomTextField } from "components/TextField";
import { AddCollaboratorIcon, CheckIcon, PenIcon } from "svg/CustomSVGIcon";
import { toast } from "react-toastify";

const AddCollaboratorModal = ({
  open,
  setOpen,
  student,
  studentList,
  additionalMember,
  setLoading,
  getCanvas,
  getAllStudent,
  canvasId,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    p: 4,
  };

  const [collaboratorList, setCollaboratorList] = useState([]);
  const [selectedCollaborator, setSelectedCollaborator] = useState([]);

  useEffect(() => {
    if (studentList) {
      const temp = studentList
        .filter((item) => item.id !== student._id)
        .filter(
          (item) =>
            !additionalMember.find((member) => member.userId === item.id)
        )
        .map((item) => {
          return {
            name: `${item.firstName} ${item.lastName}`,
            userId: item.id,
            email: item.email,
            role: item.role,
          };
        });
      setCollaboratorList(temp);
    }
  }, [studentList]);

  const handleAddCollaborator = async () => {
    setLoading(true);
    const data = selectedCollaborator;

    try {
      const response = await organizationAPI.addCollaboratorToCanvas(
        data,
        canvasId
      );

      if (response.status >= 400 && response.status < 500) {
        setLoading(false);
        toast.error("Client error: Please check your input and try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else if (response.status >= 500) {
        setLoading(false);
        toast.warning("Server error: Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else {
        getCanvas();
        setLoading(false);
        toast.success("Record Updated Successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
    getAllStudent();
    setSelectedCollaborator([]);
    setLoading(false);
  };

  const handleDeleteCollaborator = async (userId) => {
    setLoading(true);

    try {
      const response = await organizationAPI.deleteCollaboratorToCanvas(
        canvasId,
        userId
      );

      if (response.status >= 400 && response.status < 500) {
        setLoading(false);
        toast.error("Client error: Please check your input and try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else if (response.status >= 500) {
        setLoading(false);
        toast.warning("Server error: Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else {
        getCanvas();
        setLoading(false);
        toast.success("Record Updated Successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
    getAllStudent();
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className={styles.modalInviteCollaborator}>
        <div className={styles.inviteCollaboratorHeader}>
          <h1>Invite Collaborators</h1>
          <CloseIcon onClick={() => setOpen(false)} />
        </div>
        <p>Current Collaborators</p>
        <div className={styles.currentUserList}>
          <ul>
            {
              <li>
                <p className={styles.currentUser}>
                  {student.firstName} {student.lastName} (Owner)
                </p>
              </li>
            }
            {additionalMember &&
              additionalMember.map((item) => (
                <li>
                  <p className={styles.currentUser}>{item.name}</p>
                  <CloseOutlined
                    onClick={() => handleDeleteCollaborator(item.userId)}
                  />
                </li>
              ))}
          </ul>
        </div>

        <div className={styles.inviteCollaborator}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={collaboratorList}
            className={styles.autocomplete}
            getOptionLabel={(option) => option?.name}
            // defaultValue={[top100Films[13]]}
            filterSelectedOptions
            value={selectedCollaborator}
            inputValue={selectedCollaborator?.name}
            onChange={(e, value) => {
              setSelectedCollaborator(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="ADD NEW COLLABORATORS"
                placeholder="Search by name"
              />
            )}
          />
          <button
            className={styles.inviteButton}
            onClick={() => handleAddCollaborator()}
          >
            Add Personnel
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const CommentModal = ({
  canvasId,
  comment,
  user,
  student,
  studentList,
  open,
  setOpen,
  setLoading,
  getCanvas,
}) => {
  const style = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: 600,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [reply, setReply] = useState("");

  const convertDate = (dateString) => {
    return moment(dateString).format("MMM Do, YYYY");
  };

  const handleAddComment = async () => {
    setLoading(true);
    const tempRecipientList = [...studentList, student];
    const data = {
      userId: user._id,
      name: user.firstName + " " + user.lastName,
      message: reply,
      role: user.role,
      avatar: user.avatar,
      recipientList: tempRecipientList,
      schoolName: "NFTE",
    };

    try {
      const response = await organizationAPI.addCommentToCanvas(data, canvasId);

      if (response.status >= 400 && response.status < 500) {
        setLoading(false);
        toast.error("Client error: Please check your input and try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else if (response.status >= 500) {
        setLoading(false);
        toast.warning("Server error: Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else {
        getCanvas();
        setLoading(false);
        toast.success("Record Updated Successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
    const canvasResponse = getCanvas();
    setReply("");
    setLoading(false);
  };

  const handleReply = () => {
    handleAddComment();
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title">
          <div className={styles.modal_comment_header}>
            <p>Comments</p>
            <span>COLLAPSE COMMENTS</span>
          </div>
          <div className={styles.modal_border}></div>
        </Typography>
        <div className={styles.modal_comment_section}>
          {comment.map((item, index) => (
            <div className={styles.modal_body} key={index}>
              <div className={styles.post_body_head}>
                <div className={styles.post_body_image}>
                  {item.avatar && <img src={item.avatar} alt="" />}
                  <div className={styles.post_body_head_left}>
                    <div className={styles.profile}>
                      <h4>{item.name}</h4>
                    </div>
                    {item.role && (
                      <div className={styles.role}>
                        <span>{item.role.toUpperCase()}</span>
                      </div>
                    )}
                  </div>
                </div>

                <div className={styles.post_date}>
                  <p>{convertDate(item.date)}</p>
                </div>
              </div>
              <p>{item.message}</p>
            </div>
          ))}
        </div>
        <CustomTextField
          label={`Reply`}
          value={reply}
          onChange={(e) => setReply(e.target.value)}
          multiline
          className={styles.reply_field}
        />

        <div className={styles.modal_bottom}>
          <button
            disabled={!reply}
            className={styles.reply}
            onClick={handleReply}
          >
            Post Reply
          </button>
          <button className={styles.collapse} onClick={() => setOpen(false)}>
            Collapse Comments
          </button>
        </div>
      </Box>
    </Modal>
  );
};

const OverallScore = ({
  open,
  setOpen,
  justificationText,
  feedbackText,
  subtitle,
  scoreVal,
  user,
  gradeId,
  tileId,
  setLoading,
  getCanvas,
}) => {
  const [justification, setJustification] = useState(justificationText);
  const [feedback, setFeedback] = useState(feedbackText);
  const [score, setScore] = useState(scoreVal);

  const handleClose = () => setOpen(false);
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      coachId: user._id,
      coachName: user.firstName + " " + user.lastName,
      tileId: tileId,
      gradingId: gradeId,
      filedsToUpdate: {},
    };

    if (justification !== justificationText) {
      data.filedsToUpdate.justification = justification;
    }

    if (feedback !== feedbackText) {
      data.filedsToUpdate.feedback = feedback;
    }

    if (score !== scoreVal) {
      data.filedsToUpdate.score = score;
    }

    try {
      const response = await organizationAPI.updateCanvasTile(data);

      if (response.status >= 400 && response.status < 500) {
        setLoading(false);
        toast.error("Client error: Please check your input and try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else if (response.status >= 500) {
        setLoading(false);
        toast.warning("Server error: Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else {
        getCanvas();
        setLoading(false);
        toast.success("Record Updated Successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.edit_modal}>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_left}>
            <h5>Total Score</h5>
          </div>
          <div className={styles.ai_modal_top}>
            <button className={styles.save} onClick={handleSave}>
              Save and Return to Scorecard
            </button>
            <button onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className={styles.edit_modal_body_section}>
          <EditModalScoreSection
            subtitle={subtitle}
            justification={justification}
            feedback={feedback}
            setJustification={setJustification}
            setFeedback={setFeedback}
            score={score}
            setScore={setScore}
            disabledInput={false}
            actualScore={36}
          />
        </div>
      </Box>
    </Modal>
  );
};

const EditModalScoreSection = ({
  subtitle,
  justification,
  setJustification,
  feedback,
  setFeedback,
  score,
  setScore,
  actualScore,
  disabledInput,
}) => {
  return (
    <div className={styles.edit_modal_body}>
      <div className={styles.scorecard_header}>
        <span>ANSWER </span>
        <span>SCORE</span>
      </div>
      <div className={styles.scorecard_body}>
        <h4>{subtitle}</h4>
        <h4>
          <input
            className={styles.score}
            value={score}
            onChange={(e) => {
              if (e.target.value <= actualScore) {
                setScore(e.target.value);
              }
            }}
            disabled={disabledInput}
          />
          / {actualScore}
        </h4>
      </div>
      <div className={styles.progress}>
        <LinearProgress variant="determinate" value={50} />
      </div>

      <CustomTextField
        multiline
        className={styles.edit_textfield}
        id="input-with-icon-textfield"
        label={`JUSTIFICATION`}
        value={justification}
        onChange={(e) => setJustification(e.target.value)}
        disabled={disabledInput}
      />
      <CustomTextField
        multiline
        className={styles.edit_textfield}
        id="input-with-icon-textfield"
        label={`FEEDBACK`}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        disabled={disabledInput}
      />
    </div>
  );
};

const EditModal = ({
  open,
  setOpen,
  justificationText,
  feedbackText,
  subtitle,
  scoreVal,
  user,
  gradeId,
  tileId,
  setLoading,
  getCanvas,
}) => {
  const [justification, setJustification] = useState(justificationText);
  const [feedback, setFeedback] = useState(feedbackText);
  const [score, setScore] = useState(scoreVal);

  const handleClose = () => setOpen(false);
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      coachId: user._id,
      coachName: user.firstName + " " + user.lastName,
      tileId: tileId,
      gradingId: gradeId,
      filedsToUpdate: {},
    };

    if (justification !== justificationText) {
      data.filedsToUpdate.justification = justification;
    }

    if (feedback !== feedbackText) {
      data.filedsToUpdate.feedback = feedback;
    }

    if (score !== scoreVal) {
      data.filedsToUpdate.score = score;
    }

    try {
      const response = await organizationAPI.updateCanvasTile(data);

      if (response.status >= 400 && response.status < 500) {
        setLoading(false);
        toast.error("Client error: Please check your input and try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else if (response.status >= 500) {
        setLoading(false);
        toast.warning("Server error: Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else {
        getCanvas();
        setLoading(false);
        toast.success("Record Updated Successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.edit_modal}>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_left}>
            <h5>NFTE AI Scorecard</h5>
          </div>
          <div className={styles.ai_modal_top}>
            <button className={styles.save} onClick={handleSave}>
              Save and Return to Scorecard
            </button>
            <button onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className={styles.edit_modal_body_section}>
          <EditModalScoreSection
            subtitle={subtitle}
            justification={justification}
            feedback={feedback}
            setJustification={setJustification}
            setFeedback={setFeedback}
            score={score}
            setScore={setScore}
            disabledInput={false}
            actualScore={4}
          />
        </div>
      </Box>
    </Modal>
  );
};

const ViewModal = ({
  open,
  setOpen,
  justificationText,
  feedbackText,
  subtitle,
  scoreVal,
}) => {
  const [justification, setJustification] = useState(justificationText);
  const [feedback, setFeedback] = useState(feedbackText);
  const [score, setScore] = useState(scoreVal);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={styles.edit_modal}>
        <div className={styles.modal_header}>
          <div className={styles.modal_view_header_left}>
            <h5>NFTE AI Scorecard</h5>
            <button onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
          </div>
        </div>
        <div className={styles.edit_modal_body_section}>
          <EditModalScoreSection
            subtitle={subtitle}
            justification={justification}
            feedback={feedback}
            setJustification={setJustification}
            setFeedback={setFeedback}
            score={score}
            setScore={setScore}
            disabledInput={true}
            actualScore={4}
          />
        </div>
      </Box>
    </Modal>
  );
};

const Scrorecard = ({
  gradeId,
  title,
  gradeList,
  user,
  setLoading,
  getCanvas,
}) => {
  const [open, setOpen] = useState(false);
  const [viewModal, setViewModal] = useState(false);

  return (
    <div className={styles.scorecard}>
      <EditModal
        subtitle={title}
        open={open}
        setOpen={setOpen}
        justificationText={gradeList.justification}
        feedbackText={gradeList.feedback}
        scoreVal={gradeList.score}
        user={user}
        gradeId={gradeId}
        tileId={gradeList.tile_id}
        setLoading={setLoading}
        getCanvas={getCanvas}
      />
      <ViewModal
        subtitle={title}
        open={viewModal}
        setOpen={setViewModal}
        justificationText={gradeList.justification}
        feedbackText={gradeList.feedback}
        scoreVal={gradeList.score}
      />
      <div className={styles.scorecard_header}>
        <span>TOPIC</span>
        <span>SCORE</span>
      </div>
      <div className={styles.scorecard_body}>
        <h4>{gradeList.name}</h4>
        <h4>{`${gradeList.score} / ${title === "overall" ? 36 : 4}`}</h4>
      </div>
      <div className={styles.progress}>
        {title === "overall" ? (
          <LinearProgress
            variant="determinate"
            value={(gradeList.score * 100) / 36}
          />
        ) : (
          <LinearProgress
            variant="determinate"
            value={(gradeList.score * 100) / 4}
          />
        )}
      </div>
      <div className={styles.scorecard_bottom}>
        <a
          onClick={() => {
            setViewModal(true);
          }}
        >
          <span>View Details</span>
          <ArrowForwardIosIcon />
        </a>
        <a
          onClick={() => {
            setOpen(true);
          }}
        >
          Edit
        </a>
      </div>
    </div>
  );
};

const AIFeedbackModal = ({
  open,
  setOpen,
  gradeList,
  user,
  setLoading,
  getCanvas,
}) => {
  const [overall, setOverall] = useState(false);
  const [iterationId, setIterationId] = useState(null);
  const [active, setActive] = useState("scorecard");

  useEffect(() => {
    if (gradeList) {
      if (gradeList.length > 0) {
        setIterationId(gradeList[0]._id);
      }
    }
  }, [gradeList]);

  const convertDate = (dateString) => {
    return moment(dateString).format("MMM Do, YYYY");
  };

  const handleConfirmGradeStatus = async (status) => {
    setLoading(true);
    const data = {
      gradingId: iterationId,
      status,
    };

    try {
      const response = await organizationAPI.updateCanvasGradingStatus(data);

      if (response.status >= 400 && response.status < 500) {
        setLoading(false);
        toast.error("Client error: Please check your input and try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else if (response.status >= 500) {
        setLoading(false);
        toast.warning("Server error: Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else {
        getCanvas();
        setLoading(false);
        toast.success("Record Updated Successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
    setLoading(false);
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={styles.ai_modal}>
        <div className={styles.ai_modal_top}>
          {gradeList &&
            gradeList.map((item) => {
              if (item._id === iterationId) {
                return (
                  <>
                    <CustomTextField
                      id="input-with-icon-textfield"
                      label={`GRADING STATUS`}
                      value={`${item.status.toUpperCase()}`}
                      className={styles.gradelist_status}
                      disabled
                    />
                    <button
                      onClick={() => handleConfirmGradeStatus("done")}
                      className={styles.return}
                    >
                      Confirm
                    </button>
                  </>
                );
              }
            })}

          <button onClick={() => setOpen(false)} className={styles.return}>
            Return to Canvas
          </button>
          <Box
            className={styles.filter_menu}
            component="form"
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{}} className={styles.drop_down}>
              <select
                className={styles.select}
                value={iterationId}
                onChange={(e) => setIterationId(e.target.value)}
              >
                {gradeList &&
                  gradeList.map((item) => (
                    <option value={item._id}>
                      {convertDate(item.createdAt)}
                    </option>
                  ))}
              </select>
              <ArrowDropDownIcon />
            </FormControl>
          </Box>
        </div>
        <div className={styles.modal_header}>
          <div className={styles.modal_header_left}>
            <h5
              className={active === "scorecard" && styles.modal_header_card}
              onClick={() => setActive("scorecard")}
            >
              NFTE AI Scorecard
            </h5>
          </div>
          <div className={styles.modal_header_left}>
            <h5
              className={active === "activity" && styles.modal_header_card}
              onClick={() => setActive("activity")}
            >
              Activity Log
            </h5>
          </div>
        </div>

        {active === "scorecard" && (
          <>
            {gradeList &&
              gradeList.map((item) => {
                if (item._id === iterationId) {
                  return (
                    <>
                      <div className={styles.ai_body}>
                        <div className={styles.scorecard_section}>
                          {item.tiles.map((tile) => {
                            if (tile.name !== "overall")
                              return (
                                <Scrorecard
                                  gradeId={item._id}
                                  title={tile.name}
                                  gradeList={tile}
                                  user={user}
                                  setLoading={setLoading}
                                  getCanvas={getCanvas}
                                />
                              );
                          })}
                        </div>
                      </div>

                      {item.tiles.map((tile) => {
                        if (tile.name === "overall") {
                          return (
                            <div className={styles.total}>
                              <OverallScore
                                subtitle={tile.name}
                                justificationText={tile.justification}
                                feedbackText={tile.feedback}
                                open={overall}
                                setOpen={setOverall}
                                scoreVal={tile.score}
                                user={user}
                                gradeId={item._id}
                                tileId={tile.tile_id}
                                setLoading={setLoading}
                                getCanvas={getCanvas}
                              />
                              <div className={styles.top}>
                                <div className={styles.top_left}>
                                  <p>Total Score </p>
                                  <a
                                    onClick={() => {
                                      setOverall(true);
                                    }}
                                  >
                                    <span>View Details</span>
                                    <ArrowForwardIosIcon />
                                  </a>
                                </div>
                                <h4>{tile.score} / 36</h4>
                              </div>
                              <LinearProgress
                                variant="determinate"
                                value={(tile.score * 100) / 36}
                                color="success"
                              />
                            </div>
                          );
                        }
                      })}
                    </>
                  );
                }
              })}
          </>
        )}

        {active === "activity" && (
          <>
            <div className={styles.activity_log_section}>
              <ul>
                {gradeList &&
                  gradeList.map((item) => {
                    if (item._id === iterationId) {
                      return (
                        <>
                          {item.activities.map((activity) => (
                            <li>
                              <div>
                                <p className={styles.date}>
                                  {convertDate(activity.modifiedAt)}
                                </p>
                                <p className={styles.activity}>
                                  <span className={styles.user}>
                                    {activity.coach_name}{" "}
                                  </span>
                                  modified the canvas
                                </p>
                              </div>
                              <div className={styles.activity_log_border}></div>
                            </li>
                          ))}
                        </>
                      );
                    }
                  })}
              </ul>
            </div>
          </>
        )}
        {/* <div>
          {feedbackList.map((feedback, index) => (
            <NFTEAIFeedback
              feedbackText={feedback}
              deleteFeedback={deleteFeedback}
              index={index}
            />
          ))}

          <div className={styles.generate}>
            <button>
              <FeedbackOutlinedIcon /> Generate New Feedback
            </button>
          </div>
        </div> */}
      </div>
    </Modal>
  );
};

const CustomWidget = ({ title, value, setValue }) => {
  return (
    <div className={styles.widget}>
      <div className={styles.wrapper_container}>
        <div class={styles.custom_wrapper}>
          <label class={styles.custom_wrapper_float} for="input">
            {title}
          </label>
          <textarea onChange={(e) => setValue(e.target.value)}>
            {value}
          </textarea>
        </div>
      </div>
    </div>
  );
};

const ProblemAndSolution = ({
  title,
  info,
  gradeList,
  lastNumber,
  gradeName,
  border = true,
  type,
  workflowList,
  setLoading,
  user,
  getCanvas,
}) => {
  const [gradingScore, setGradingScore] = useState(0);
  const [tileId, setTileId] = useState(0);
  const [justification, setJustification] = useState("");
  const [feedback, setFeedback] = useState("");
  const [gradeId, setGradeId] = useState(0);
  useEffect(() => {
    if (gradeList) {
      if (gradeList.length > 0) {
        setLoading(false);
        setTileId(
          gradeList[0].tiles.filter(
            (item) =>
              String(item.name).toLowerCase() ===
              String(gradeName).toLowerCase()
          )[0]?.tile_id
        );
        setJustification(
          gradeList[0].tiles.filter(
            (item) =>
              String(item.name).toLowerCase() ===
              String(gradeName).toLowerCase()
          )[0]?.justification
        );
        setFeedback(
          gradeList[0].tiles.filter(
            (item) =>
              String(item.name).toLowerCase() ===
              String(gradeName).toLowerCase()
          )[0]?.feedback
        );
        setGradeId(gradeList[0]._id);
        setGradingScore(
          gradeList[0].tiles.filter(
            (item) =>
              String(item.name).toLowerCase() ===
              String(gradeName).toLowerCase()
          )[0]?.score
        );
      }
    } else {
      setLoading(true);
    }
  }, [gradeList]);

  const handleSave = async (e) => {
    e.preventDefault();

    if (gradeList && gradeList.length > 0) {
      setLoading(true);
      const data = {
        coachId: user._id,
        coachName: user.firstName + " " + user.lastName,
        tileId: tileId,
        gradingId: gradeId,
        filedsToUpdate: {},
      };
      if (
        justification !==
        gradeList[0].tiles.filter(
          (item) =>
            String(item.name).toLowerCase() === String(gradeName).toLowerCase()
        )[0]?.justification
      ) {
        data.filedsToUpdate.justification = justification;
      }

      if (
        feedback !==
        gradeList[0].tiles.filter(
          (item) =>
            String(item.name).toLowerCase() === String(gradeName).toLowerCase()
        )[0]?.feedback
      ) {
        data.filedsToUpdate.feedback = feedback;
      }

      if (
        gradingScore !==
        gradeList[0].tiles.filter(
          (item) =>
            String(item.name).toLowerCase() === String(gradeName).toLowerCase()
        )[0]?.score
      ) {
        data.filedsToUpdate.score = gradingScore;
      }

      if (Object.keys(data.filedsToUpdate).length > 0) {
        try {
          const response = await organizationAPI.updateCanvasTile(data);

          if (response.status >= 400 && response.status < 500) {
            setLoading(false);
            toast.error(
              "Client error: Please check your input and try again.",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                theme: "light",
              }
            );
          } else if (response.status >= 500) {
            setLoading(false);
            toast.warning("Server error: Please try again later.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "light",
            });
          } else {
            getCanvas();
            setLoading(false);
            toast.success("Record Updated Successfully.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "light",
            });
          }
        } catch (error) {
          setLoading(false);
          toast.error("An unexpected error occurred. Please try again later.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "light",
          });
        }
      }

      setLoading(false);
    }
  };

  if (workflowList || gradeList) {
    return (
      <div className={styles.problem_body}>
        <div className={styles.widget_header}>
          <h1>{title}</h1>
          {gradeList && gradeList.length > 0 ? (
            gradeList[0].tiles.map((item, index) => {
              if (
                String(item.name).toLowerCase() ===
                String(gradeName).toLowerCase()
              ) {
                return (
                  <PageNumber
                    currentIndex={item.score}
                    lastIndex={lastNumber}
                    key={index}
                    setGradingScore={setGradingScore}
                    submitSave={handleSave}
                  />
                );
              }
            })
          ) : (
            <></>
          )}
        </div>
        <div className={styles.problem_content}>
          {workflowList && (
            <div className={styles.left_content}>
              <h3
                style={
                  workflowList &&
                  workflowList.filter(
                    (item) =>
                      String(item.type).toLowerCase() ===
                      String(type).toLowerCase()
                  ).length > 0
                    ? { color: "#000" }
                    : { color: "rgb(185, 7, 7)" }
                }
              >
                {info}
              </h3>
              <ol type="1">
                {workflowList &&
                  workflowList.map((item, index) => {
                    if (item.type === type) {
                      return <li key={index}>{item.body}</li>;
                    }
                  })}
              </ol>
            </div>
          )}

          {gradeList && gradeList.length > 0 ? (
            gradeList[0].tiles.map((item, index) => {
              if (
                String(item.name).toLowerCase() ===
                String(gradeName).toLowerCase()
              ) {
                return (
                  <div className={styles.group} key={index}>
                    <div
                      className={
                        workflowList && workflowList.length > 0
                          ? styles.sizebox2
                          : styles.sizebox3
                      }
                    >
                      <CustomWidget
                        title={"Feedback"}
                        value={item.feedback}
                        setValue={setFeedback}
                      />
                    </div>
                    <div
                      className={
                        workflowList && workflowList.length > 0
                          ? styles.sizebox2
                          : styles.sizebox3
                      }
                    >
                      <CustomWidget
                        title={"Justification"}
                        value={item.justification}
                        setValue={setJustification}
                      />
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <></>
          )}
        </div>
        {border && <div className={styles.border}></div>}
      </div>
    );
  } else {
    return <div></div>;
  }
};

const PageNumber = ({
  currentIndex,
  lastIndex,
  setGradingScore,
  submitSave,
}) => {
  const [enablePaginationEdit, setEnablePaginationEdit] = useState(false);
  const [score, setScore] = useState(currentIndex);
  const [progressWidth, setProgressWidth] = useState(0);

  useEffect(() => {
    if (score > lastIndex) {
      setScore(lastIndex);
    }
    if (score < 0 || score === "") {
      setScore(0);
    }
    const progress = (score / lastIndex) * 100;
    setProgressWidth(progress);
  }, [score, lastIndex, setGradingScore]);

  return (
    <div className={styles.indicator}>
      <button className={styles.save_btn} onClick={submitSave}>
        Save
      </button>
      <button
        className={styles.edit_and_check}
        onClick={() => {
          setEnablePaginationEdit(!enablePaginationEdit);
          if (enablePaginationEdit) {
            setGradingScore(score);
          }
        }}
      >
        {enablePaginationEdit ? (
          <CheckIcon fill={"#1e7759"} />
        ) : (
          <PenIcon fill={"#000"} />
        )}
      </button>
      <div className={styles.progress}>
        <div
          className={styles.progress_bar}
          style={{ width: `${progressWidth}%` }}
        ></div>
      </div>
      <div className={styles.count}>
        {enablePaginationEdit ? (
          <input
            className={styles.num}
            onChange={(e) => {
              const value = e.target.value;
              setScore(value === "" ? "" : parseInt(value, 10));
            }}
            value={score}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setEnablePaginationEdit(false);
                setGradingScore(score);
              }
            }}
          />
        ) : (
          <p>{score}</p>
        )}

        <p>/</p>
        <p>{lastIndex}</p>
      </div>
    </div>
  );
};

const QueryDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [comment, setComment] = useState([]);
  const [workflowList, setWorkflowList] = useState([]);
  const [canvas, setCanvas] = useState(null);
  const [student, setStudent] = useState(null);
  const [gradeList, setGradeList] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [openAIModal, setOpenAIModal] = useState(false);
  const [collaboratorOpen, setCollaboratorOpen] = useState(false);
  const [additionalMember, setAdditionalMember] = useState([]);
  const [studentList, setStudentList] = useState([]);

  const location = useLocation();
  const canvasId = location.pathname.split("/")[4];

  const getCanvas = async () => {
    setLoading(true);
    const response = await organizationAPI.getIndividualCanvasForCoach(
      canvasId
    );

    setCanvas(response.data);
    setWorkflowList(response.data.workflow);
    setComment(response.data.comments);
    setStudent(response.data.userProfile);
    setAdditionalMember(response.data.additionalMembers);
    const gradingResponse =
      await organizationAPI.getGradingAIForIndividualCanvas(canvasId);

    if (response.data && gradingResponse.data) {
      setDataLoaded(true);
      setLoading(false);
    } else {
      setDataLoaded(false);
      setLoading(true);
    }

    setGradeList(gradingResponse.data.reverse());

    setLoading(false);
  };

  const getProfile = async () => {
    setLoading(true);
    const response = await organizationAPI.getUser();
    if (response.data) {
      setDataLoaded(true);
    } else {
      setDataLoaded(false);
    }
    setUser(response.data.user);
    setLoading(false);
  };

  const getAllStudent = async () => {
    const studentResponse = await organizationAPI.getAllCanvasStudent();
    const tempStudentList = studentResponse.data.filter((item) => {
      if (item.id !== student._id) return true;
      else return false;
    });

    setStudentList(tempStudentList);
  };

  const handleArchive = async () => {
    setLoading(true);

    const status = canvas?.status === "archived" ? "progress" : "archived";

    try {
      await organizationAPI.updateCanvasStatus(canvasId, status);
      getCanvas();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getProfile();
    getCanvas();
  }, [dataLoaded]);

  useEffect(() => {
    if (user && student) getAllStudent();
  }, [user, student]);

  const handleGradingStatus = async (status) => {
    setLoading(true);

    try {
      const response = await organizationAPI.updateCanvasStatus(
        canvasId,
        status
      );

      if (response.status >= 400 && response.status < 500) {
        setLoading(false);
        toast.error("Client error: Please check your input and try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else if (response.status >= 500) {
        setLoading(false);
        toast.warning("Server error: Please try again later.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      } else {
        getCanvas();
        setLoading(false);
        toast.success("Record Updated Successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error("An unexpected error occurred. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
    setLoading(false);
    navigate("/coach/learner/canvas");
  };

  const convertDate = (dateString) => {
    return moment(dateString).format("MMM Do, YYYY");
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      {user && student && studentList && additionalMember && (
        <AddCollaboratorModal
          open={collaboratorOpen}
          setOpen={setCollaboratorOpen}
          student={student}
          studentList={studentList}
          additionalMember={additionalMember}
          user={user}
          setLoading={setLoading}
          getCanvas={getCanvas}
          getAllStudent={getAllStudent}
          canvasId={canvasId}
        />
      )}
      <AIFeedbackModal
        open={openAIModal}
        gradeList={gradeList}
        setOpen={setOpenAIModal}
        user={user}
        setLoading={setLoading}
        getCanvas={getCanvas}
      />

      <CommentModal
        canvasId={canvasId}
        comment={comment}
        user={user}
        open={openComment}
        setOpen={setOpenComment}
        setLoading={setLoading}
        getCanvas={getCanvas}
        student={student}
        studentList={studentList}
      />

      <MainContent active="canvas" user={user}>
        <div className={styles.main}>
          <div className={styles.content}>
            <div className={styles.header}>
              <h1>Weyland-Yutani Corporation</h1>
              <div className={styles.header_buttons}>
                <button onClick={handleArchive} className={styles.comments}>
                  {canvas && canvas.status === "archived" ? (
                    <UnarchiveOutlinedIcon />
                  ) : (
                    <ArchiveOutlinedIcon />
                  )}
                </button>
                <button
                  className={styles.comment}
                  onClick={() => setOpenComment(true)}
                >
                  <CommentOutlinedIcon /> Comments
                </button>{" "}
                <button
                  onClick={() => setCollaboratorOpen(true)}
                  className={styles.comments}
                >
                  <AddCollaboratorIcon /> Add Collaborator
                </button>
                <button
                  className={styles.btn_green}
                  onClick={() => handleGradingStatus("progress")}
                >
                  <UndoOutlinedIcon />
                  Send Back to Student
                </button>
                <button
                  className={styles.complete}
                  onClick={() => handleGradingStatus("done")}
                >
                  <DoneOutlinedIcon />
                  Complete
                </button>
              </div>
            </div>

            <div className={styles.border}></div>

            <div className={styles.input_fields}>
              {canvas && (
                <div class={styles.wrapper2}>
                  <label class={styles.float} for="input">
                    BUSINESS NAME
                  </label>
                  <input value={`${canvas.canvasName}`} disabled />
                </div>
              )}
              {student && (
                <CustomTextField
                  label={`STUDENT NAME`}
                  value={`${student.firstName} ${student.lastName}`}
                  disabled
                />
              )}
              {canvas && (
                <CustomTextField
                  label={`DATE`}
                  value={`${convertDate(canvas.createdAt)}`}
                  disabled
                />
              )}
              {canvas && (
                <CustomTextField
                  label={`TASK STATUS`}
                  value={`${canvas.status.toUpperCase()}`}
                  disabled
                />
              )}

              {/* <div class={styles.wrapper}>
                <label class={styles.float} for="input">
                  TASK STATUS
                </label>
                <select name="format" id="format">
                  <option value="pdf" selected>
                    In Progress
                  </option>
                  <option value="txt">Complete</option>
                  <option value="epub">Rejected</option>
                </select>
              </div> */}
            </div>

            <div className={styles.body}>
              {gradeList && (gradeList || []).length > 0 && (
                <ProblemAndSolution
                  title={"Cumulative Feedback"}
                  lastNumber={36}
                  gradeList={gradeList}
                  gradeName={"overall"}
                  setLoading={setLoading}
                  user={user}
                  getCanvas={getCanvas}
                />
              )}
              <ProblemAndSolution
                title={"Problems"}
                info={
                  workflowList.filter((item) => item.type === "problem")
                    .length > 0
                    ? `Top ${
                        workflowList.filter((item) => item.type === "problem")
                          .length
                      } problems`
                    : "The student did not complete this section."
                }
                type={"problem"}
                workflowList={workflowList}
                lastNumber={4}
                gradeList={gradeList}
                gradeName={"problems"}
                setLoading={setLoading}
                user={user}
                getCanvas={getCanvas}
              />
              <ProblemAndSolution
                title={"Solutions"}
                info={
                  workflowList.filter((item) => item.type === "solution")
                    .length > 0
                    ? `Top ${
                        workflowList.filter((item) => item.type === "solution")
                          .length
                      } problems`
                    : "The student did not complete this section."
                }
                type={"solution"}
                workflowList={workflowList}
                lastNumber={4}
                gradeList={gradeList}
                gradeName={"solutions"}
                setLoading={setLoading}
                user={user}
                getCanvas={getCanvas}
              />
              <ProblemAndSolution
                title={"Key Metrics"}
                info={
                  workflowList.filter((item) => item.type === "metrics")
                    .length > 0
                    ? "Key activities you measure"
                    : "The student did not complete this section."
                }
                type={"metrics"}
                workflowList={workflowList}
                lastNumber={4}
                gradeList={gradeList}
                gradeName={"key metrics"}
                setLoading={setLoading}
                user={user}
                getCanvas={getCanvas}
              />
              <ProblemAndSolution
                title={"Unique Value Proposition"}
                info={
                  workflowList.filter((item) => item.type === "uniqueValue")
                    .length > 0
                    ? "Unique Value Propositions"
                    : "The student did not complete this section."
                }
                type={"uniqueValue"}
                workflowList={workflowList}
                lastNumber={4}
                gradeList={gradeList}
                gradeName={"unique value proposition"}
                setLoading={setLoading}
                user={user}
                getCanvas={getCanvas}
              />
              <ProblemAndSolution
                title={"Unfair Advantage"}
                info={
                  workflowList.filter((item) => item.type === "unfairAdvantage")
                    .length > 0
                    ? "Can’t be easily copied/bought"
                    : "The student did not complete this section."
                }
                type={"unfairAdvantage"}
                workflowList={workflowList}
                lastNumber={4}
                gradeList={gradeList}
                gradeName={"Unfair advantage"}
                setLoading={setLoading}
                user={user}
                getCanvas={getCanvas}
              />
              <ProblemAndSolution
                title={"Channels"}
                info={
                  workflowList.filter((item) => item.type === "channels")
                    .length > 0
                    ? "Path to customers"
                    : "The student did not complete this section."
                }
                type={"channels"}
                workflowList={workflowList}
                lastNumber={4}
                gradeList={gradeList}
                gradeName={"channels"}
                setLoading={setLoading}
                user={user}
                getCanvas={getCanvas}
              />
              <ProblemAndSolution
                title={"Customer Segments"}
                info={
                  workflowList.filter((item) => item.type === "customerSegment")
                    .length > 0
                    ? "Target customers"
                    : "The student did not complete this section."
                }
                type={"customerSegment"}
                workflowList={workflowList}
                lastNumber={4}
                gradeList={gradeList}
                gradeName={"customer segments"}
                setLoading={setLoading}
                user={user}
                getCanvas={getCanvas}
              />
              <ProblemAndSolution
                title={"Cost Structure"}
                info={
                  workflowList.filter((item) => item.type === "costStructure")
                    .length > 0
                    ? "What are the most important costs inherent in our business model? Which key resources/activities are most expensive?"
                    : "The student did not complete this section."
                }
                type={"costStructure"}
                workflowList={workflowList}
                lastNumber={4}
                gradeList={gradeList}
                gradeName={"cost structure"}
                setLoading={setLoading}
                user={user}
                getCanvas={getCanvas}
              />
              <ProblemAndSolution
                title={"Revenue Streams"}
                info={
                  workflowList.filter((item) => item.type === "revenueStream")
                    .length > 0
                    ? "For what value are our customers really willing to pay? For what do they currently pay? How are they currently paying? How would they prefer to pay?"
                    : "The student did not complete this section."
                }
                type={"revenueStream"}
                workflowList={workflowList}
                lastNumber={4}
                gradeList={gradeList}
                gradeName={"revenue streams"}
                setLoading={setLoading}
                user={user}
                getCanvas={getCanvas}
              />
            </div>
          </div>
        </div>
      </MainContent>
    </>
  );
};

export default QueryDashboard;
