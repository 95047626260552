import { IncreaseIcon } from "svg/CustomSVGIcon";
import styles from "./styles/Leaderboard.module.css";

const Widget = ({ title, info, percentage, increase = true, date }) => {
  return (
    <div className={styles.widget}>
      <p>{title}</p>
      <h1>{info}</h1>
      <div
        className={styles.status}
        style={{ color: increase ? "#2aa87e" : "#e03838" }}
      >
        <IncreaseIcon />{" "}
        <p>
          {percentage}% {increase ? "increase" : "decrease"}
        </p>{" "}
      </div>
      <span>vs. {date}</span>
    </div>
  );
};

const Leaderboard = () => {
  return (
    <div>
      <div className={styles.grid}>
        <Widget
          title="Total Call Duration"
          info="414m 01s"
          percentage={"24"}
          increase={true}
          date={"Last Month"}
        />
        <Widget
          title="Total Call Duration"
          info="414m 01s"
          percentage={"24"}
          increase={true}
          date={"Last Month"}
        />
        <Widget
          title="Total Call Duration"
          info="414m 01s"
          percentage={"24"}
          increase={true}
          date={"Last Month"}
        />
        <Widget
          title="Total Call Duration"
          info="414m 01s"
          percentage={"24"}
          increase={true}
          date={"Last Month"}
        />
      </div>
    </div>
  );
};

export default Leaderboard;
