import styles from "./styles/ConversationalAI.module.css";
import {
  BurgerIcon,
  PhoneIcon,
  DateTimeIcon,
  EyeIcon,
  ArrowDown,
} from "svg/CustomSVGIcon";


const CallerContent = ({ active = false, image, name, designation, quirk }) => {
  return (
    <div className={styles.buyer_card}>
      <div className={styles.profile_photo}>
        <img src={"https://cdn1.edgedatg.com/aws/v2/abc/SharkTank/person/942357/658ffd051a71b2cb8bcae612645a957d/579x579-Q90_658ffd051a71b2cb8bcae612645a957d.jpg"} alt={name} />
      </div>
      <div className={styles.buyer_info}>
        <h5>{name}</h5>
        <span>{designation?.substr(0, 38) + `...`}</span>
      </div>
      <div className={styles.quirk}>{quirk}</div>
      <a className={styles.icon1}>
        <PhoneIcon fill={"#E20074"} />
      </a>
      <a className={styles.icon2}>
        <BurgerIcon fill={"#8CA19F"} />
      </a>
    </div>
  );
};

const CallerInformation = ({ image, name, datetime, duration }) => {
  return (
    <div className={styles.call_Info}>
      <div className={styles.caller}>
        <img src={image} alt={name} className={styles.caller_img} />
        <p>{name}</p>
      </div>
      <div className={styles.info}>
        <div className={styles.datetime}>
          <DateTimeIcon fill={"#8ca19f"} />
          <span>{datetime}</span>
        </div>
        <div className={styles.datetime}>
          <PhoneIcon fill={"#8ca19f"} />
          <span>{duration}</span>
        </div>
        <EyeIcon />
      </div>
    </div>
  );
};




const Persona = () => {
  return (
    <div className={styles.persona}>
      <div className={styles.buyers}>
            <CallerContent
              name="Mark Cuban"
              designation={
                "Senior Director of Business Coperation of Something"
              }
              quirk={"Cordial"}
            />
            <CallerContent
              name="Mark Cuban"
              designation={
                "Senior Director of Business Coperation of Something"
              }
              quirk={"Cordial"}
            />
            <CallerContent
              name="Mark Cuban"
              designation={
                "Senior Director of Business Coperation of Something"
              }
              quirk={"Cordial"}
            />
          </div>
          <h2>Past Calls</h2>
          <div className={styles.container2}>
            <CallerInformation
              image={"https://cdn1.edgedatg.com/aws/v2/abc/SharkTank/person/942357/658ffd051a71b2cb8bcae612645a957d/579x579-Q90_658ffd051a71b2cb8bcae612645a957d.jpg"}
              name={"Mark Cuban"}
              datetime={"3:32 PM, Sun, Mar 17, 2024"}
              duration={"9m 16s"}
            />
            <CallerInformation
              image={"https://cdn1.edgedatg.com/aws/v2/abc/SharkTank/person/942357/658ffd051a71b2cb8bcae612645a957d/579x579-Q90_658ffd051a71b2cb8bcae612645a957d.jpg"}
              name={"Mark Cuban"}
              datetime={"3:32 PM, Sun, Mar 17, 2024"}
              duration={"9m 16s"}
            />
            <CallerInformation
              image={"https://cdn1.edgedatg.com/aws/v2/abc/SharkTank/person/942357/658ffd051a71b2cb8bcae612645a957d/579x579-Q90_658ffd051a71b2cb8bcae612645a957d.jpg"}
              name={"Mark Cuban"}
              datetime={"3:32 PM, Sun, Mar 17, 2024"}
              duration={"9m 16s"}
            />
            <CallerInformation
              image={"https://cdn1.edgedatg.com/aws/v2/abc/SharkTank/person/942357/658ffd051a71b2cb8bcae612645a957d/579x579-Q90_658ffd051a71b2cb8bcae612645a957d.jpg"}
              name={"Mark Cuban"}
              datetime={"3:32 PM, Sun, Mar 17, 2024"}
              duration={"9m 16s"}
            />
            </div>
            <button className={styles.view_all}>
              <ArrowDown />
              <p>VIEW ALL</p>
            </button>
    </div>
  )
}

export default Persona
