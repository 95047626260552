import React, { useEffect } from "react";
import { useState } from "react";
import "./sidebar.css";
import Logo from "../images/logo.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  DashboardIcon,
  QueryIcon,
  SettingsIcon,
  CanvasIcon,
  AnnouncementIcon,
} from "./sidebaricon";
import {
  AdmissionIcon,
  AssisstantAI,
  DocumentAnalyzerNavIcon,
  InsightIcon,
  TeacherAIIcon,
  TestIcon,
  ConversationalAIIcon,
} from "svg/CustomSVGIcon";

import organizationAPI from "api/api";

import LoadingOverlay from "./LoadingOverlay";

const Sidebar = ({ active, role, avatar }) => {
  const [selectedIcon, setSelectedIcon] = useState("DashboardIcon");
  const [navList, setNavList] = useState([
    {
      name: "Dashboard",
      icon: (
        <DashboardIcon color={active === "dashboard" ? "#FF5B35" : "#F3F8F8"} />
      ),
      link: "/dashboard",
      widget: "adminDashboard",
      active: "dashboard",
    },
    {
      name: "Lean Canvas",
      icon: <CanvasIcon color={active === "canvas" ? "#FF5B35" : "#F3F8F8"} />,
      link: `/${role?.toLowerCase()}/learner/canvas`,
      widget: "canvas",
      active: "canvas",
    },
    {
      name: "Documents",
      icon: (
        <DocumentAnalyzerNavIcon
          fill={active === "documentanalysis" ? "#FF5B35" : "#F3F8F8"}
        />
      ),
      link: `/${role?.toLowerCase()}/documentanalysis/dashboard`,
      widget: "documentAI",
      active: "documentanalysis",
    },
    {
      name: "Resume",
      icon: (
        <DocumentAnalyzerNavIcon
          fill={active === "resumeanalysis" ? "#FF5B35" : "#F3F8F8"}
        />
      ),
      link: `/${role?.toLowerCase()}/resumeanalysis/dashboard`,
      widget: "resumeAI",
      active: "resumeanalysis",
    },
    {
      name: "Chat",
      icon: <QueryIcon color={active === "query" ? "#FF5B35" : "#F3F8F8"} />,
      link: `/${role?.toLowerCase()}/query-archive`,
      widget: "chat",
      active: "query",
    },
    {
      name: "Insights",
      icon: <InsightIcon fill={active === "insight" ? "#FF5B35" : "#F3F8F8"} />,
      link: `/${role?.toLowerCase()}/insights`,
      widget: "insights",
      active: "insight",
    },
    {
      name: "Essay",
      icon: (
        <AdmissionIcon fill={active === "admission" ? "#FF5B35" : "#F3F8F8"} />
      ),
      link: `/${role?.toLowerCase()}/admission/dashboard`,
      widget: "essay",
      active: "admission",
    },
  ]);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [organizationMeta, setOrganizationMeta] = useState({});

  const [loading, setLoading] = useState(false);

  const getOrganizationMetaByRole = async () => {
    setLoading(true);
    try {
      const organizationCode =
        await organizationAPI.getOrganizationCodeFromLocalStorage();
      const response = await organizationAPI.getOrganizationMetaByRole(
        organizationCode
      );

      setOrganizationMeta(response.data);
    } catch (error) {
      console.log("Error fetching organization meta", error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getOrganizationMetaByRole();
    console.log("-----------------> ", organizationMeta.role?.widgtes);
  }, []);

  return (
    <div className="sidebar">
      {loading && <LoadingOverlay />}
      <ul className={`sidebarLogo`}>
        <li>
          <a href="#">
            <img src={organizationMeta.navbarLogo} alt="Logo" />

            {!openMobileMenu && (
              <MenuIcon onClick={() => setOpenMobileMenu(true)} />
            )}
            {openMobileMenu && (
              <CloseIcon onClick={() => setOpenMobileMenu(false)} />
            )}
          </a>
        </li>
      </ul>
      <ul
        className={!openMobileMenu ? `navigation mobileDropdown` : `navigation`}
      >
        {navList.map((navItem, index) => {
          if (organizationMeta.role?.widgtes?.includes(navItem.widget)) {
            return (
              <li key={index}>
                <a href={navItem.link}>
                  {navItem.icon}
                  <span
                    className={
                      active === navItem.active ? "sidebar_active" : ""
                    }
                  >
                    {navItem.name}
                  </span>
                </a>
              </li>
            );
          }
        })}

        {role === "ADMIN" &&
          organizationMeta.role?.widgtes?.includes("teacherAI") && (
            <li>
              <a href={role && `/${role.toLowerCase()}/teacherai/dashboard`}>
                <TeacherAIIcon
                  fill={active === "teacherai" ? "#FF5B35" : "#F3F8F8"}
                />
                <span
                  className={active === "teacherai" ? "sidebar_active" : ""}
                >
                  Teacher AI
                </span>
              </a>
            </li>
          )}

        {role === "COACH" &&
          organizationMeta.role?.widgtes?.includes("teacherAI") && (
            <li>
              <a href={role && `/${role.toLowerCase()}/teacherai/tutor`}>
                <TeacherAIIcon
                  fill={active === "teacherai" ? "#FF5B35" : "#F3F8F8"}
                />
                <span
                  className={active === "teacherai" ? "sidebar_active" : ""}
                >
                  Teacher AI
                </span>
              </a>
            </li>
          )}

        {(role === "ADMIN" || role === "COACH" || role === "STUDENT") &&
          organizationMeta.role?.widgtes?.includes("psychAI") && (
            <li>
              <a href={role && `/${role.toLowerCase()}/psych/dashboard`}>
                <TestIcon fill={active === "psych" ? "#FF5B35" : "#F3F8F8"} />
                <span className={active === "psych" ? "sidebar_active" : ""}>
                  Assessment
                </span>
              </a>
            </li>
          )}

        {role === "COACH" &&
          organizationMeta.role?.widgtes?.includes("teacherAI") && (
            <li>
              <a href={role && `/${role.toLowerCase()}/assistantai`}>
                <AssisstantAI
                  fill={active === "assisstantAI" ? "#FF5B35" : "#F3F8F8"}
                />
                <span
                  className={active === "assisstantAI" ? "sidebar_active" : ""}
                >
                  Assisstant AI
                </span>
              </a>
            </li>
          )}
        {role === "COACH" &&
          organizationMeta.role?.widgtes?.includes("teacherAI") && (
            <li>
              <a href={role && `/${role.toLowerCase()}/conversationalai`}>
                <ConversationalAIIcon
                  fill={active === "conversationalAI" ? "#E20074" : "#F3F8F8"}
                />
                <span
                  className={
                    active === "conversationalAI" ? "sidebar_active" : ""
                  }
                >
                  Conversational AI
                </span>
              </a>
            </li>
          )}

        {/* { (
          <li>
            <a href={role && `/${role.toLowerCase()}/query-archive`}>
              <AnnouncementIcon
                color={active === 'announcement' ? '#FF5B35' : '#F3F8F8'}
              />
              <span
                className={active === 'announcement' ? 'sidebar_active' : ''}
              >
                Announcement
              </span>
            </a>
          </li>
        )} */}
      </ul>

      <ul className={!openMobileMenu ? `profile mobileDropdown` : `profile`}>
        {organizationMeta.role?.widgtes?.includes("settings") && (
          <li>
            <a href="/profile">
              <SettingsIcon
                color={active === "settings" ? "#FF5B35" : "#F3F8F8"}
              />
              <span className={active === "settings" ? "sidebar_active" : ""}>
                Settings
              </span>
            </a>
          </li>
        )}

        {organizationMeta.role?.widgtes?.includes("profile") && (
          <li>
            <a href="/profile">
              {avatar ? (
                <img src={avatar} alt="Logo" referrerPolicy="no-referrer" />
              ) : (
                <AccountCircleIcon />
              )}

              <span className={active === "profile" ? "sidebar_active" : ""}>
                Profile
              </span>
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};

const MainContent = ({ user, active, children }) => {
  return (
    <div className="sidebarLayout">
      {user && user.role && (
        <Sidebar active={active} role={user.role} avatar={user.avatar} />
      )}
      <div className="mainContent">{children}</div>
    </div>
  );
};

export { MainContent, Sidebar };
